import * as actionTypes from '../../action-types/master-action-types/master-action-types';

const initialState = {
    countries: [],
    instruments: [],
    musicianStatus: [],
    phoneTypes: [],
    relations: [],
    roles: [],
    musicianInfo: {},
    totalCreditBalance: null
}

export const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_COUNTRIES:
            return { ...state, countries: action.payload }
        case actionTypes.ADD_INSTRUMENTS:
            return { ...state, instruments: action.payload }
        case actionTypes.ADD_MUSICIAN_STATUS:
            return { ...state, musicianStatus: action.payload }
        case actionTypes.ADD_PHONE_TYPES:
            return { ...state, phoneTypes: action.payload }
        case actionTypes.ADD_RELATION:
            return { ...state, relations: action.payload }
        case actionTypes.ADD_ROLES:
            return { ...state, roles: action.payload }
        case actionTypes.MUSICIAN_INFO:
            return { ...state, musicianInfo: action.payload }
        case actionTypes.TOTAL_CREDIT_BALANCE:
            return { ...state, totalCreditBalance: action.payload }
        default:
            return state
    }
}