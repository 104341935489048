export const ADD_COUNTRIES = 'ADD_COUNTRIES' // get from api & push that to redux store

export const ADD_INSTRUMENTS = 'ADD_INSTRUMENTS' // get from api & push that to redux store

export const ADD_MUSICIAN_STATUS = 'ADD_MUSICIAN_STATUS' // get from api & push that to redux store

export const ADD_PHONE_TYPES = 'ADD_PHONE_TYPES' // get from api & push that to redux store

export const ADD_RELATION = 'ADD_RELATION' // get from api & push that to redux store

export const ADD_ROLES = 'ADD_ROLES' // get from api & push that to redux store

export const TOTAL_CREDIT_BALANCE = 'TOTAL_CREDIT_BALANCE' // get from api & push that to redux store

export const MUSICIAN_INFO = 'MUSICIAN_INFO' // get from api & push that to redux store